import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { RemoveScroll } from "react-remove-scroll";
import { Link } from "gatsby";
import kebabCase from "lodash.kebabcase";

const animateIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(-10px);
}

100% {
  opacity: 1;
  }
`;

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding: 20px 30px 0 30px;
  z-index: 90;

  @media (max-width: 700px) {
    padding: 20px 15px 0 15px;
  }

  transition: 500ms background-color ease;

  &.is-closed {
    background-color: transparent;
    /* visibility: hidden; */

    & .menu-container {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
  }

  &.is-open {
    background-color: #000;
    visibility: visible;

    & .menu-container {
      opacity: 1;
      visibility: visible;
    }
  }

  & button {
    &.menu {
      position: absolute;
      width: 30px;
      height: 20px;
      top: 48px;
      right: 30px;
      transition-duration: 0.5s;

      z-index: 20;

      @media (max-width: 700px) {
        top: 15px;
        right: 15px;
      }

      & .icon {
        transition-duration: 0.5s;
        position: absolute;
        height: 2px;
        width: 30px;
        top: 10px;
        background-color: #fff;
      }
      & .icon:before {
        transition-duration: 0.5s;
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: #fff;
        content: "";
        top: -10px;
        left: 0;
      }
      & .icon:after {
        transition-duration: 0.5s;
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: #fff;
        content: "";
        top: 10px;
        left: 0;
      }
      &.open .icon {
        transition-duration: 0.5s;
        background: transparent;
      }
      &.open .icon:before {
        transform: rotateZ(45deg) scaleX(1.25) translate(6px, 6px);
      }
      &.open .icon:after {
        transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
      }
      &:hover {
        cursor: pointer;
      }
    }

    &.closed + ol {
      opacity: 0;
      visibility: hidden;
      background-color: transparent;
    }

    &.open + ol {
      opacity: 1;
      visibility: visible;
      background-color: #000;

      /* position: absolute; */
      /* top: 0; */
      /* left: 0; */
      /* right: 0; */

      z-index: 10;

      & li {
        animation-name: ${animateIn};
        animation-duration: 500ms;
        animation-delay: calc(var(--animation-order) * 300ms);
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
      }
    }
  }

  & .menu-container {
    width: 100%;
    padding: 100px 0 90px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    height: calc(${(props) => props.windowHeight}px - 20px);
  }

  & ol {
    display: flex;
    flex-direction: column;
    position: relative;

    transition: 500ms background-color ease;

    & li {
      position: relative;
      margin: 0 0 0 0;

      color: #fff;
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;

      /* font-family: "Eina01-SemiBold", sans-serif; */
      text-transform: uppercase;

      & button,
      & a {
        position: relative;

        color: #fff;
        font-size: 32px;
        line-height: 48px;
        font-weight: 500;
        letter-spacing: 0.07em;

        text-transform: uppercase;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #fff;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        &:hover,
        &.current {
          &::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }

        & svg {
          position: absolute;
          margin-left: 9px;
          top: 26px;
        }

        // media query for 375px
        @media (max-width: 375px) {
          font-size: 28px;
          line-height: 42px;
        }
      }

      & .work-menu-container {
        display: flex;
        flex-direction: column;
        padding: 0 15px 15px 15px;

        transition: 300ms all ease;

        width: max-content;

        & li {
          line-height: 30px;

          /* & a.tag-button,
          & button {
            text-decoration: underline;
            text-decoration-color: transparent;
            text-underline-offset: 4px;

            transition: 300ms text-decoration-color ease;

            &:hover,
            &.is-active {
              text-decoration-color: #fff;
            }
          } */

          & > a,
          & > button {
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              transform: scaleX(0);
              height: 2px;
              bottom: 0;
              left: 0;
              background-color: #fff;
              transform-origin: bottom right;
              transition: transform 0.25s ease-out;
            }
          }

          &:hover,
          &.current {
            & > a,
            & > button {
              &::after {
                transform: scaleX(1);
                transform-origin: bottom left;
              }
            }
          }
        }

        &.is-closed {
          display: none;
        }

        &.is-open {
          display: block;

          & li {
            animation-name: ${animateIn};
            animation-duration: 500ms;
            animation-delay: calc(var(--animation-order) * 100ms);
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
          }
        }
      }
    }

    &.footer-menu {
      flex-direction: row;
      justify-content: space-between;

      & li {
        font-size: 12px;
        line-height: 60px;
        letter-spacing: 0.6px;

        /* font-family: "Eina01-SemiBold", sans-serif; */

        & a {
          font-size: 12px;
          line-height: 60px;
          letter-spacing: 0.6px;

          /* font-family: "Eina01-SemiBold", sans-serif; */
        }
      }
    }
  }
`;

const Logo = styled.h1`
  top: 2px;
  position: fixed;
  z-index: 1000;

  font-size: 35px;
  line-height: 60px;
  letter-spacing: 0.25em;

  color: ${(props) => props.textColor};

  &.eina-01-bold {
    font-family: "Eina01-Bold", sans-serif !important;
  }
  &.eina-03-bold {
    font-family: "Eina03-Bold", sans-serif !important;
  }
  &.peignot-light {
    font-family: "Peignot-Light", sans-serif !important;
  }
  &.peignot-demi {
    font-family: "Peignot-Demi", sans-serif !important;
  }
  &.peignot-bold {
    font-family: "Peignot-Bold", sans-serif !important;
  }

  & a {
    line-height: 60px;
    letter-spacing: 0.25em;

    display: block;
    width: fit-content;
    margin: 0 auto;
  }

  &.layout-centred {
    left: 50%;
    transform: translateX(-50%);
  }

  &.layout-left {
    left: 30px;
  }
`;

export const MobileMenu = ({
  links,
  location,
  windowHeight,
  isMenuOpen,
  setIsMenuOpen,
  data,
}) => {
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <Nav
      className={`is-${isMenuOpen ? `open` : `closed`}`}
      windowHeight={windowHeight}
    >
      {isMenuOpen && (
        <Logo
          className={`logo ${kebabCase(
            data.prismicSettings.data.logo_font
          )} layout-${kebabCase(data.prismicSettings.data.logo_position)}`}
          textColor={data.prismicSettings.data.logo_color}
        >
          <Link to={`/`}>LCS</Link>
        </Logo>
      )}

      <button
        type="button"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className={`menu ${isMenuOpen ? `open` : `closed`}`}
      >
        <div className="icon" />
      </button>

      {isMenuOpen && (
        <RemoveScroll forwardProps>
          <div className="menu-container">
            <ol>{links}</ol>
          </div>
        </RemoveScroll>
      )}
    </Nav>
  );
};
