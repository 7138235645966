exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "homepage": {
      return `/`;
    }

    case "about": {
      return `/${doc.uid}/`;
    }

    case "project": {
      return `/work/${doc.uid}/`;
    }

    case "category": {
      return `/category/${doc.uid}/`;
    }

    case "settings": {
      return `/`;
    }

    default: {
      return `/${doc.uid}/`;
    }
  }
};
