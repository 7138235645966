import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import kebabCase from "lodash.kebabcase";

const FooterWrapper = styled.footer`
  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 0 30px;
  line-height: 60px;

  font-size: 12px;
  /* font-family: "Eina01-SemiBold", sans-serif; */
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;

  background-color: #000;

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & li {
      margin: 0 30px 0 0;
    }
  }

  & p {
    margin: 0;
  }

  @media (max-width: 700px) {
    padding: 0 15px;
  }
`;

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicSettings {
        data {
          body1 {
            ... on PrismicSettingsDataBody1PageLink {
              id
              slice_type
              primary {
                page {
                  document {
                    ... on PrismicPage {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicSettingsDataBody1SectionLink {
              id
              slice_type
              primary {
                page {
                  document {
                    ... on PrismicPage {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
                section_link
              }
            }
            ... on PrismicSettingsDataBody1ExternalLink {
              id
              slice_type
              primary {
                title
                page {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const links = data.prismicSettings.data.body1.map((link, index) => {
    if (link.slice_type === "page_link") {
      return (
        <li key={`link_${index}_${link.id}`}>
          <Link to={link.primary.page.document.url} activeClassName="current">
            {link.primary.page.document.data.title.text}
          </Link>
        </li>
      );
    }

    if (link.slice_type === "section_link") {
      return (
        <li key={`link_${index}_${link.id}`}>
          <Link
            to={link.primary.page.document.url}
            state={{ section: kebabCase(link.primary.section_link) }}
            activeClassName="current"
          >
            {link.primary.section_link}
          </Link>
        </li>
      );
    }

    if (link.slice_type === "external_link") {
      return (
        <li key={`link_${index}_${link.id}`}>
          <a
            href={link.primary.page.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {link.primary.title}
          </a>
        </li>
      );
    }
  });

  return (
    <FooterWrapper>
      <ol>{links}</ol>
    </FooterWrapper>
  );
};
