import React, { useEffect, useState } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import kebabCase from "lodash.kebabcase";
import smoothscroll from "smoothscroll-polyfill";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

// Get Urls correct
import { Link, useStaticQuery, graphql } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { linkResolver } from "../link-resolver/linkResolver";

// SEO
import { DefaultSEO } from "../seo/default-seo";

// Context
import { TagProvider } from "../context/active-tag";

// Styles
import GlobalStyles from "../styles/globalStyles";
import "../styles/normalize.css";

// Components
import { App } from "./app";

const Container = styled.div`
  min-height: ${(props) => props.height}px;

  & main {
    min-height: ${(props) => props.height}px;
  }

  &.about {
    padding: 145px 30px 0 30px;

    @media (max-width: 900px) {
      padding: 105px 15px 0 15px;
    }

    & .text-block-container {
      & p {
        @media (max-width: 900px) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    & .two-column-text-outer-container {
      grid-column-gap: 120px;
      margin: 120px 0;
      padding: 0;

      @media (max-width: 1300px) {
        grid-column-gap: 60px;
      }

      @media (max-width: 500px) {
        margin: 0 !important;

        & hr {
          display: none !important;
        }

        & .column-left,
        & .column-right {
          & .inner-text-column {
            margin: 5px 0;
          }
        }

        & .column-left {
          margin-top: 1em !important;
        }

        & p {
          display: none;
        }

        & .is-open-true {
          & p {
            display: block;
          }

          & button {
            &::after {
              content: "–";
            }
          }
        }

        & button {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: baseline;
          width: 100%;

          &::after {
            content: "+";
          }
        }
      }
    }

    & .module {
      margin-top: 60px;
      margin-bottom: 60px;
    }

    & main {
      padding: 0 90px;

      @media (max-width: 1600px) {
        padding: 0 60px;
      }

      @media (max-width: 1300px) {
        padding: 0 30px;
      }

      @media (max-width: 1200px) {
        padding: 0 15px;
      }

      @media (max-width: 900px) {
        padding: 0;
      }
    }
  }

  &.contact {
    padding: 145px 30px 0 30px;

    @media (max-width: 900px) {
      padding: 105px 15px 0 15px;
    }
  }

  &.about,
  &.contact {
    /* padding: 145px 30px 0 30px;

    @media (max-width: 900px) {
      padding: 105px 15px 0 15px;
    } */

    & footer {
      padding: 0;
    }

    & .module.text-block-container {
      &:first-of-type {
        margin-top: 0;
      }

      &.text-position-pinned-bottom-left {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        min-height: calc(${(props) => props.height}px - 145px - 60px);

        @media (max-width: 900px) {
          min-height: calc(${(props) => props.height}px - 105px - 60px);
        }

        & p {
          text-align: left;
        }
      }
    }

    & main {
      min-height: calc(${(props) => props.height}px - 145px - 60px);

      & .page {
        min-height: calc(${(props) => props.height}px - 145px - 60px);
      }

      @media (max-width: 900px) {
        min-height: calc(${(props) => props.height}px - 105px - 60px);

        & .page {
          min-height: calc(${(props) => props.height}px - 105px - 60px);
        }
      }
    }

    & footer {
      & ol {
        & li {
          & a.current {
            display: none;
          }
        }
      }
    }
  }

  &.font-eina-01 {
    font-family: "Eina01-Regular", sans-serif;

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & p {
      font-family: "Eina01-Regular", sans-serif;
    }

    & .semibold,
    & .semibold > h1,
    & .semibold > h2,
    & .semibold > h3,
    & .semibold > h4,
    & .semibold > p,
    & .title-text,
    & .title-text > h1,
    & .title-text > h2,
    & .title-text > h3,
    & .title-text > h4,
    & .title-text > p,
    & strong,
    & b,
    & footer {
      font-family: "Eina01-SemiBold", sans-serif;
    }

    & .project-title-container {
      & h1,
      & h2 {
        font-family: "Eina01-SemiBold", sans-serif;
      }
    }

    & header & nav {
      & li,
      & a,
      & button {
        font-family: "Eina01-Regular", sans-serif;
      }
    }

    & ol {
      & li {
        & a,
        & button {
          font-family: "Eina01-SemiBold", sans-serif;
        }
      }
    }

    &.footer-menu {
      & li {
        font-family: "Eina01-SemiBold", sans-serif;

        & a {
          font-family: "Eina01-SemiBold", sans-serif;
        }
      }
    }

    & .floating-project-title-container {
      & h1,
      & h2 {
        font-family: "Eina01-SemiBold", sans-serif;
      }
    }

    & .overlay-text-container {
      & h1,
      & h2 {
        font-family: "Eina01-SemiBold", sans-serif;
      }
    }

    & .text-block-container,
    & .description-container {
      & h2 {
        font-family: "Eina01-SemiBold", sans-serif;
      }
    }

    & .news-container {
      & .see-more-button-container {
        & .see-more-button {
          font-family: "Eina01-SemiBold", sans-serif;
        }
      }
    }

    & .other-work-container {
      & .other-work-list {
        & .other-work-item {
          & .other-work-item-title {
            & h1,
            & h2 {
              font-family: "Eina01-SemiBold", sans-serif;
            }
          }
        }
      }
    }
  }

  &.font-eina-03 {
    font-family: "Eina03-Regular", sans-serif;

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & p {
      font-family: "Eina03-Regular", sans-serif;
    }

    & .semibold,
    & .semibold > h1,
    & .semibold > h2,
    & .semibold > h3,
    & .semibold > h4,
    & .semibold > p,
    & .title-text,
    & .title-text > h1,
    & .title-text > h2,
    & .title-text > h3,
    & .title-text > h4,
    & .title-text > p,
    & strong,
    & b,
    & footer {
      font-family: "Eina03-SemiBold", sans-serif;
    }

    & .project-title-container {
      & h1,
      & h2 {
        font-family: "Eina03-SemiBold", sans-serif;
      }
    }

    & header & nav {
      & li,
      & a,
      & button {
        font-family: "Eina03-Regular", sans-serif;
      }
    }

    & ol {
      & li {
        & a,
        & button {
          font-family: "Eina03-SemiBold", sans-serif;
        }
      }
    }

    &.footer-menu {
      & li {
        font-family: "Eina03-SemiBold", sans-serif;

        & a {
          font-family: "Eina03-SemiBold", sans-serif;
        }
      }
    }

    & .floating-project-title-container {
      & h1,
      & h2 {
        font-family: "Eina03-SemiBold", sans-serif;
      }
    }

    & .overlay-text-container {
      & h1,
      & h2 {
        font-family: "Eina03-SemiBold", sans-serif;
      }
    }

    & .text-block-container,
    & .description-container {
      & h2 {
        font-family: "Eina03-SemiBold", sans-serif;
      }
    }

    & .news-container {
      & .see-more-button-container {
        & .see-more-button {
          font-family: "Eina03-SemiBold", sans-serif;
        }
      }
    }

    & .other-work-container {
      & .other-work-list {
        & .other-work-item {
          & .other-work-item-title {
            & h1,
            & h2 {
              font-family: "Eina03-SemiBold", sans-serif;
            }
          }
        }
      }
    }
  }

  &.font-circular-standard {
    font-family: "CircularXXWeb-Regular", sans-serif;

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & p {
      font-family: "CircularXXWeb-Regular", sans-serif;
    }

    & .semibold,
    & .semibold > h1,
    & .semibold > h2,
    & .semibold > h3,
    & .semibold > h4,
    & .semibold > p,
    & .title-text,
    & .title-text > h1,
    & .title-text > h2,
    & .title-text > h3,
    & .title-text > h4,
    & .title-text > p,
    & strong,
    & b,
    & footer {
      font-family: "CircularXXWeb-Bold", sans-serif;
    }

    & .project-title-container {
      & h1,
      & h2 {
        font-family: "CircularXXWeb-Bold", sans-serif;
      }
    }

    & header & nav {
      & li,
      & a,
      & button {
        font-family: "CircularXXWeb-Regular", sans-serif;
      }
    }

    & ol {
      & li {
        & a,
        & button {
          font-family: "CircularXXWeb-Bold", sans-serif;
        }
      }
    }

    &.footer-menu {
      & li {
        font-family: "CircularXXWeb-Bold", sans-serif;

        & a {
          font-family: "CircularXXWeb-Bold", sans-serif;
        }
      }
    }

    & .floating-project-title-container {
      & h1,
      & h2 {
        font-family: "CircularXXWeb-Bold", sans-serif;
      }
    }

    & .overlay-text-container {
      & h1,
      & h2 {
        font-family: "CircularXXWeb-Bold", sans-serif;
      }
    }

    & .text-block-container,
    & .description-container {
      & h2 {
        font-family: "CircularXXWeb-Bold", sans-serif;
      }
    }

    & .news-container {
      & .see-more-button-container {
        & .see-more-button {
          font-family: "CircularXXWeb-Bold", sans-serif;
        }
      }
    }

    & .other-work-container {
      & .other-work-list {
        & .other-work-item {
          & .other-work-item-title {
            & h1,
            & h2 {
              font-family: "CircularXXWeb-Bold", sans-serif;
            }
          }
        }
      }
    }
  }

  & .caption-text,
  & .caption-text > h1,
  & .caption-text > h2,
  & .caption-text > h3,
  & .caption-text > h4,
  & .caption-text > p {
    font-size: ${(props) => props.captionTextSize};
    line-height: 18px;
    margin: 15px 0 0 0;
  }
`;

const Layout = ({ children }) => {
  const height = use100vh();

  // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  const staticData = useStaticQuery(graphql`
    query {
      prismicSettings {
        _previewable
        data {
          body_text
          caption_text_size
        }
      }
    }
  `);

  const data = useMergePrismicPreviewData(staticData);

  if (!hasMounted) {
    return null;
  }

  return (
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <>
        <DefaultSEO />
        <Location>
          {({ location }) => {
            return (
              <TagProvider>
                <Container
                  className={`${kebabCase(location.pathname)} font-${kebabCase(
                    data.prismicSettings.data.body_text
                  )}`}
                  height={height}
                  captionTextSize={data.prismicSettings.data.caption_text_size}
                >
                  <GlobalStyles />

                  <App
                    location={location.pathname}
                    children={children}
                    height={height}
                  />
                </Container>
              </TagProvider>
            );
          }}
        </Location>
      </>
    </PrismicProvider>
  );
};

export default Layout;
