exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-prismic-category-url-js": () => import("./../../../src/pages/{PrismicCategory.url}.js" /* webpackChunkName: "component---src-pages-prismic-category-url-js" */),
  "component---src-pages-prismic-page-url-js": () => import("./../../../src/pages/{PrismicPage.url}.js" /* webpackChunkName: "component---src-pages-prismic-page-url-js" */),
  "component---src-pages-prismic-project-url-js": () => import("./../../../src/pages/{PrismicProject.url}.js" /* webpackChunkName: "component---src-pages-prismic-project-url-js" */)
}

