import React, { createContext, useState } from "react";

// Create a new context
const TagContext = createContext();

// Create a provider component to wrap your app
const TagProvider = ({ children }) => {
  // Define the state for the tag value
  const [tag, setTag] = useState("All");

  // Function to update the tag value
  const updateTag = (newTag) => {
    setTag(newTag);
  };

  // Create the context value
  const contextValue = {
    tag,
    updateTag,
  };

  // Provide the context value to the children
  return (
    <TagContext.Provider value={contextValue}>{children}</TagContext.Provider>
  );
};

export { TagContext, TagProvider };
