import React, { useEffect } from "react";
import { motion } from "framer-motion";

// Components
import { Header } from "./header";
import { Footer } from "./footer";

const duration = 0.5;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
};

export const App = ({ location, height, children }) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 550);
  }, [location]);

  return (
    <>
      <Header location={location} height={height} />

      <motion.main
        key={location}
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        {children}
      </motion.main>

      <Footer location={location} />
    </>
  );
};
