import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import kebabCase from "lodash.kebabcase";

// Previews
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Context
import { TagContext } from "../context/active-tag";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

const Logo = styled.h1`
  top: 0;
  position: absolute;
  z-index: 100;

  font-size: 35px;
  line-height: 60px;
  letter-spacing: 2.6px;

  color: ${(props) => props.textColor};

  &.eina-01-bold {
    font-family: "Eina01-Bold", sans-serif !important;
  }
  &.eina-03-bold {
    font-family: "Eina03-Bold", sans-serif !important;
  }
  &.peignot-light {
    font-family: "Peignot-Light", sans-serif !important;
  }
  &.peignot-demi {
    font-family: "Peignot-Demi", sans-serif !important;
  }
  &.peignot-bold {
    font-family: "Peignot-Bold", sans-serif !important;
  }

  & a {
    line-height: 65px;
    display: block;
    width: fit-content;

    margin: 0 auto;
    letter-spacing: 0.25em;
  }

  &.layout-centred {
    left: 50%;
    transform: translateX(-50%);
  }

  &.layout-left {
    left: 30px;
  }
`;

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 0 19px;
  height: 60px;
  z-index: 90;

  display: grid;

  & nav {
    & li,
    & a,
    & button {
      color: ${(props) => props.textColor};
    }
  }

  @media (max-width: 700px) {
    padding: 0 15px;
    line-height: 35px;
    /* z-index: 200; */
  }
`;

export const Header = ({ location, height }) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 700px)");

  const [isWorkMenuOpen, setIsWorkMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Access the tag and updateTag function from the context
  const { tag, updateTag } = useContext(TagContext);

  const staticData = useStaticQuery(graphql`
    {
      prismicSettings {
        _previewable
        data {
          logo_color
          logo_font
          logo_position
          header_color
          header_text_color
          body {
            ... on PrismicSettingsDataBodyPageLink {
              id
              slice_type
              primary {
                page {
                  document {
                    ... on PrismicHomepage {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPage {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicAbout {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicSettingsDataBodySectionLink {
              id
              slice_type
              primary {
                page {
                  document {
                    ... on PrismicPage {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
                section
              }
            }
            ... on PrismicSettingsDataBodyDropdownMenu {
              id
              slice_type
              primary {
                dropdown_title
              }
              items {
                page {
                  document {
                    ... on PrismicHomepage {
                      id
                      url
                      type
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicCategory {
                      id
                      url
                      type
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const data = useMergePrismicPreviewData(staticData);

  const links = data.prismicSettings.data.body.map((link, index) => {
    if (link.slice_type === "dropdown_menu") {
      const dropdownItems = link.items
        .filter((item) => item.page.document !== null)
        .map((item, index) => {
          if (location === `/`) {
            if (item.page.document.type === "category") {
              return (
                <li
                  key={`dropdown_item_${index}_${item.page.document.id}`}
                  style={{ "--animation-order": index + 1 }}
                  className={
                    item.page.document.id === tag
                      ? `current dropdown-item`
                      : `dropdown-item`
                  }
                >
                  <button
                    onClick={() => {
                      setIsMenuOpen(false);
                      setIsWorkMenuOpen(false);
                      updateTag(item.page.document.id);
                      document
                        .getElementById("projects-container")
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    {item.page.document.data.title.text}
                  </button>
                </li>
              );
            } else {
              return (
                <li
                  key={`dropdown_item_${index}_${item.page.document.id}`}
                  style={{ "--animation-order": index + 1 }}
                  className={
                    `All` === tag ? `current dropdown-item` : `dropdown-item`
                  }
                >
                  <button
                    onClick={() => {
                      setIsMenuOpen(false);
                      setIsWorkMenuOpen(false);
                      updateTag(`All`);
                      document
                        .getElementById("projects-container")
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    All
                  </button>{" "}
                </li>
              );
            }
          } else {
            if (item.page.document.type === "category") {
              return (
                <li
                  style={{ "--animation-order": index + 1 }}
                  key={`dropdown_item_${index}_${item.page.document.id}`}
                  className={
                    item.page.document.id === tag
                      ? `current dropdown-item`
                      : `dropdown-item`
                  }
                  onClick={() => {
                    setIsMenuOpen(false);
                    setIsWorkMenuOpen(false);
                    updateTag(item.page.document.id);
                  }}
                >
                  <Link
                    to={`/`}
                    state={{ transition: true }}
                    activeClassName="current"
                  >
                    {item.page.document.data.title.text}
                  </Link>
                </li>
              );
            } else {
              return (
                <li
                  style={{ "--animation-order": index + 1 }}
                  key={`dropdown_item_${index}_${item.page.document.id}`}
                  className={
                    `All` === tag ? `current dropdown-item` : `dropdown-item`
                  }
                  onClick={() => {
                    setIsWorkMenuOpen(false);
                    updateTag(`All`);
                  }}
                >
                  <Link
                    to={`/`}
                    state={{ transition: true }}
                    activeClassName="current"
                  >
                    All
                  </Link>{" "}
                </li>
              );
            }
          }
        });

      return (
        <li
          key={`link_${index}_${link.id}`}
          style={{ "--animation-order": index + 1 }}
          className="menu-item"
        >
          <button
            className={`toggle-work-menu ${isWorkMenuOpen ? `open` : `closed`}`}
            type="button"
            onClick={() => {
              setIsWorkMenuOpen(!isWorkMenuOpen);
            }}
          >
            {link.primary.dropdown_title}
          </button>

          <ol
            className={`work-menu-container ${
              isWorkMenuOpen ? `is-open` : `is-closed`
            }`}
            onMouseLeave={() => {
              setIsWorkMenuOpen(!isWorkMenuOpen);
            }}
          >
            {dropdownItems}
          </ol>
        </li>
      );
    }

    if (link.slice_type === "page_link") {
      return (
        <li
          key={`link_${index}_${link.id}`}
          style={{ "--animation-order": index + 1 }}
          className="menu-item"
        >
          <Link
            activeClassName="current"
            to={link.primary.page.document.url}
            onClick={() => {
              setIsWorkMenuOpen(false);
              // setIsMenuOpen(false);
            }}
          >
            {link.primary.page.document.data.title.text}
          </Link>
        </li>
      );
    }

    if (link.slice_type === "section_link") {
      if (location === `/about/`) {
        return (
          <li
            key={`link_${index}_${link.id}`}
            style={{ "--animation-order": index + 1 }}
            className="menu-item"
          >
            <button
              type="button"
              onClick={() => {
                setIsWorkMenuOpen(false);

                document
                  .getElementById(kebabCase(link.primary.section))
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              {link.primary.section}
            </button>
          </li>
        );
      } else {
        return (
          <li
            key={`link_${index}_${link.id}`}
            style={{ "--animation-order": index + 1 }}
            className="menu-item"
          >
            <Link
              activeClassName="current"
              to={link.primary.page.document.url}
              state={{ section: kebabCase(link.primary.section) }}
              onClick={() => {
                setIsWorkMenuOpen(false);
              }}
            >
              {link.primary.section}
            </Link>
          </li>
        );
      }
    }
  });

  return (
    <>
      <Logo
        className={`logo ${kebabCase(
          data.prismicSettings.data.logo_font
        )} layout-${kebabCase(data.prismicSettings.data.logo_position)}`}
        textColor={data.prismicSettings.data.logo_color}
      >
        <Link to={`/`}>LCS</Link>
      </Logo>

      <HeaderWrapper
        bgColor={data.prismicSettings.data.header_color}
        textColor={data.prismicSettings.data.header_text_color}
      >
        {isDesktop ? (
          <DesktopMenu
            links={links}
            location={location}
            windowHeight={height}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            setIsWorkMenuOpen={setIsWorkMenuOpen}
          />
        ) : (
          <MobileMenu
            links={links}
            location={location}
            windowHeight={height}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            setIsWorkMenuOpen={setIsWorkMenuOpen}
            data={data}
          />
        )}
      </HeaderWrapper>
    </>
  );
};
