import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const animateIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(5px);
}

100% {
  opacity: 1;
  transform: translateY(0);
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    &.menu {
      position: relative;
      margin: 19px 0;
      width: 30px;
      height: 20px;

      transition-duration: 0.5s;
      transform: scale(0.7);

      & .icon {
        transition-duration: 0.5s;
        position: absolute;
        height: 2px;
        width: 30px;
        top: 10px;
        background-color: #fff;
      }
      & .icon:before {
        transition-duration: 0.5s;
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: #fff;
        content: "";
        top: -10px;
        left: 0;
      }
      & .icon:after {
        transition-duration: 0.5s;
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: #fff;
        content: "";
        top: 10px;
        left: 0;
      }
      &.open .icon {
        transition-duration: 0.5s;
        background: transparent;
      }
      &.open .icon:before {
        transform: rotateZ(45deg) scaleX(1.25) translate(6px, 6px);
      }
      &.open .icon:after {
        transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  &.closed > ol {
    opacity: 0;
    visibility: hidden;
  }

  &.open > ol {
    opacity: 1;
    visibility: visible;

    & li.menu-item {
      animation-name: ${animateIn};
      animation-duration: 500ms;
      animation-delay: calc(var(--animation-order) * 100ms);
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
    }
  }

  & ol {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 0 30px 0 0;

    & li {
      position: relative;
      margin: 0 20px 0 0;
      width: fit-content;

      & a,
      & button {
        display: inline-block;

        color: #fff;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.12em;

        /* font-family: "Eina01-SemiBold", sans-serif; */
        text-transform: uppercase;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 1px;
          bottom: 0;
          left: 0;
          background-color: #fff;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }
      }

      &.open,
      &:hover {
        & > a,
        & > button {
          &::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }
      }

      & button {
        position: relative;

        & svg {
          position: absolute;
          margin-left: 7px;
          top: 3px;
        }
      }

      & .work-menu-container {
        position: absolute;
        top: 54px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        transition: 300ms all ease;

        width: max-content;

        & li {
          line-height: 24px;
          margin: 0 0 10px 0;

          & > a {
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              transform: scaleX(0);
              height: 1px;
              bottom: 0;
              left: 0;
              background-color: #fff;
              transform-origin: bottom right;
              transition: transform 0.25s ease-out;
            }
          }

          &:hover,
          &.current {
            & > a,
            & > button {
              &::after {
                transform: scaleX(1);
                transform-origin: bottom left;
              }
            }
          }
        }

        &.is-closed {
          opacity: 0;
          visibility: hidden;
        }

        &.is-open {
          opacity: 1;
          visibility: visible;

          & li {
            animation-name: ${animateIn};
            animation-duration: 500ms;
            animation-delay: calc(var(--animation-order) * 100ms);
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
          }
        }
      }
    }
  }
`;

export const DesktopMenu = ({
  links,
  location,
  isMenuOpen,
  setIsMenuOpen,
  setIsWorkMenuOpen,
}) => {
  useEffect(() => {
    setIsMenuOpen(false);
    setIsWorkMenuOpen(false);
  }, [location]);

  return (
    <Nav className={`${isMenuOpen ? `open` : `closed`}`}>
      <ol>{links}</ol>

      <button
        type="button"
        className={`menu ${isMenuOpen ? `open` : `closed`}`}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
          setIsWorkMenuOpen(false);
        }}
      >
        <div className="icon" />
      </button>
    </Nav>
  );
};
