import * as React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import { AnimatePresence } from "framer-motion";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Config
const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      about: React.lazy(() => import("./src/pages/about")),
      page: React.lazy(() => import("./src/pages/{PrismicPage.url}")),
      project: React.lazy(() => import("./src/pages/{PrismicProject.url}")),
      homepage: React.lazy(() => import("./src/pages/index")),
      category: React.lazy(() => import("./src/pages/{PrismicCategory.url}")),
    },
  },
];

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    <AnimatePresence mode="wait">{element}</AnimatePresence>
  </PrismicPreviewProvider>
);

const transitionDelay = 0;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(0, 0);
    // window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);

  return false;
};

// export const onRouteUpdate = ({ location }) => scrollToAnchor(location);

// /**
//  *
//  * @desc - a function to jump to the correct scroll position
//  * @param {Object} location -
//  * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
//  */
// function scrollToAnchor(location, mainNavHeight = 0) {
//   // Check for location so build does not fail
//   if (location && location.hash) {
//     const item = document.querySelector(`${location.hash}`).offsetTop;

//     window.scrollTo({
//       top: item - mainNavHeight,
//       behavior: "smooth",
//     });
//   }

//   return true;
// }
