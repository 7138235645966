import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,
html.wf-loading {
  body {
    opacity: 0;
  }


  .logo {
    display: none;
  }
}

html,
body {
  scroll-behavior: smooth;
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }

  .logo {
    display: block;
  }
}

html {
  background-color: #000;
  overflow-x: hidden;
  overscroll-behavior: none;
}

body {
  /* font-family: "Eina01-Regular", sans-serif; */
  font-weight: normal;
  font-style: normal;

  font-size: 16px;
  line-height: 26px;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #fff;
  background-color: #000;
  margin: 0;

  transition: 250ms opacity ease;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 700px) {
    font-size: 14px;
    line-height: 18px;
  }
}

input {
  border-radius: 0;
}


h1,h2,h3,h4,h5{
  /* font-family: "Eina01-Regular", sans-serif; */
  font-weight: normal;
  font-style: normal;

  margin: 0 !important;
}

.center-text{
  text-align: center;
}


.small-text,
.small-text > h1,
.small-text > h2,
.small-text > h3,
.small-text > h4,
.small-text > p {
  font-size: 16px;
  line-height: 26px;

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 700px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.medium-text,
.medium-text > h1,
.medium-text > h2,
.medium-text > h3,
.medium-text > h4,
.medium-text > p {
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 1300px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 18px;
  }
}


.large-text,
.large-text > h1,
.large-text > h2,
.large-text > h3,
.large-text > h4,
.large-text > p {
  font-size: 30px;
  line-height: 45px;

  @media (max-width: 1300px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.extra-large-text,
.extra-large-text > h1,
.extra-large-text > h2,
.extra-large-text > h3,
.extra-large-text > h4,
.extra-large-text > p {
  font-size: 40px;
  line-height: 55px;

  @media (max-width: 1300px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 700px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.title-text,
.title-text > h1,
.title-text > h2,
.title-text > h3,
.title-text > h4,
.title-text > p,
strong,
b {
  font-size: 14px;
  /* font-family: "Eina01-SemiBold", sans-serif; */
  font-weight: 500;
  letter-spacing: 0.12em;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 11px;
    /* letter-spacing: 0.4px; */
  }
}

.lh-40 {
  line-height: 40px;
}

.lh-60 {
  line-height: 60px;
}

/* .semibold,
.semibold > h1,
.semibold > h2,
.semibold > h3,
.semibold > h4,
.semibold > p {
  font-family: "Eina01-SemiBold", sans-serif;
} */

/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

.gatsby-image-wrapper{
  width: 100%;
  height: 100%;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border:0;
    padding:0;
    margin:0;
    appearance: none;

    cursor: pointer;
    color: inherit;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}
`;

export default GlobalStyle;
