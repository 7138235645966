module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.lcs.studio"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Eina01-Regular","Eina01-RegularItalic","Eina01-SemiBold","Eina01-SemiBoldItalic","Eina01-Bold","Eina03-Regular","Eina03-RegularItalic","Eina03-SemiBold","Eina03-SemiBoldItalic","Eina03-Bold","Peignot-Light","Peignot-Demi","Peignot-Bold","PalatinoProItalic","PalatinoProRoman","CircularXXWeb-Italic","CircularXXWeb-Regular","CircularXXWeb-BoldItalic","CircularXXWeb-Bold"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/global/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lcs-studio","accessToken":"MC5aSnJGS3hFQUFCMEFaZFZ5.Q--_vWTvv73vv70T77-9I--_ve-_ve-_vU_vv73vv73vv70vHO-_ve-_vTXvv73vv73vv73vv73vv73vv71rM--_vTMZLg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LCS Studio","short_name":"LCS Studio","start_url":"/","background_color":"#000","theme_color":"#000","display":"standalone","icon":"src/favicon/favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b8c06a1e5e99ed669ec46291f9b019b4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-50},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
